@import 'src/common/styles/settings.module.css';

.root {
  padding: 0 var(--size-20) var(--size-40);

  @media (--breakpoint-desktop) {
    padding: 0 0 var(--size-40);
  }
}

.section {
  &:first-of-type {
    padding-top: var(--size-28);
  }

  &:last-of-type {
    padding-top: var(--size-48);
  }

  @media (--breakpoint-desktop) {
    &:first-of-type,
    &:last-of-type {
      padding-top: var(--size-36);
    }
  }
}

.logo {
  max-width: var(--2-cols);
  padding-bottom: var(--size-28);

  @media (--breakpoint-desktop) {
    width: calc(var(--3-cols) + var(--gutter));
    max-width: unset;
    padding-bottom: var(--size-36);
  }
}

.instructions {
  display: grid;
  grid-row-gap: var(--size-64);
  grid-template-columns: auto;
  padding-top: var(--size-8);

  @media (--breakpoint-desktop) {
    grid-column-gap: calc(var(--cell-length) + calc(2 * var(--gutter)));
    grid-template-columns: var(--5-cols) var(--5-cols);
  }
}

.buttonContainer {
  margin-top: var(--size-20);
}
