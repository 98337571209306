@import 'src/common/styles/settings.module.css';

.headingWrapper {
  padding-top: var(--size-12);
}

.logoWrapper {
  display: flex;

  width: 91px;

  margin-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    margin-bottom: var(--size-24);
  }
}

.buttonWrapper {
  width: 100%;

  margin-top: var(--size-24);

  @media (--breakpoint-desktop) {
    width: max-content;
  }
}

.separator {
  height: 1px;

  margin: var(--size-24) 0;

  background-color: var(--rebranding-md-color-grey-10);

  border: 0;
}

.sandboxLinkText {
  color: var(--rebranding-md-color-brand-black);

  font-size: var(--size-20);
  line-height: var(--size-28);
}

.sandboxLink {
  display: inline-flex;
  align-items: center;
  gap: var(--size-8);

  margin-top: var(--size-8);

  color: var(--rebranding-md-color-brand-purple);

  font-size: var(--size-16);
  line-height: var(--size-20);

  text-decoration: none;
}

.externalLinkIcon {
  display: inline-flex;
}

.buttonExternalLinkIcon {
  margin-left: var(--size-8);
}

.productionLinkWrapper {
  margin-top: var(--size-24);
}

.productionLink {
  margin-top: var(--size-12);
}

.list {
  margin-top: var(--size-20);
}

.listItem::before {
  content: '•';

  position: absolute;

  left: calc(-1 * var(--size-8));

  size: var(--size-14);
}

.listItem {
  position: relative;

  margin-left: var(--size-8);

  list-style: none;
}

.listItem:not(:first-child) {
  margin-top: var(--size-20);
}
