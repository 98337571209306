@import 'src/common/styles/settings.module.css';

:root {
  --sidebar-width: calc(var(--cell-length) + calc(2 * var(--gutter)));
}

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 68px;
  padding: var(--size-16) var(--size-20);

  background-color: var(--rebranding-md-color-orange-lightest);

  @media (--breakpoint-desktop) {
    padding: var(--size-24);
    padding-left: calc(var(--sidebar-width));
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  @media (--breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 calc(var(--cell-length) + var(--size-20));
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  @media (--breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.status,
.dot {
  color: var(--rebranding-md-color-orange);

  font-size: var(--size-16);
  font-weight: var(--font-weight-roobert-pro-semi-bold);
  line-height: var(--size-20);
}

.status {
  white-space: nowrap;
}

.dot {
  display: none;

  @media (--breakpoint-desktop) {
    display: inline;
  }
}

.description {
  color: var(--rebranding-md-color-orange-dark);

  font-size: var(--size-16);
  line-height: var(--size-20);
}

.cta {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.button {
  display: flex;
  align-items: center;

  padding: 0;

  background-color: transparent;
  border: none;
  color: var(--rebranding-md-color-orange-dark);
  cursor: pointer;

  font-size: var(--size-16);
  line-height: var(--size-20);
  text-decoration: none;

  white-space: nowrap;
  gap: var(--size-8);
}
