@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 64px;
  padding-inline: 20px;

  text-align: center;

  @media (--breakpoint-desktop) {
    padding-top: 236px;
  }
}

.logoContainer {
  display: flex;
  width: 91px;
  margin-bottom: var(--size-28);
}

.messageContainer {
  margin-top: var(--size-12);
  margin-bottom: var(--size-28);
}

.buttonContainer {
  width: 100%;

  margin-top: var(--size-16);

  @media (--breakpoint-desktop) {
    width: max-content;
  }
}

.emailRegistered {
  display: flex;
  align-items: center;
  margin-top: var(--size-16);

  color: var(--rebranding-md-color-green);
  gap: var(--size-4);
}
