@import 'src/common/styles/settings.module.css';

.page {
  position: absolute;

  flex: 1 1 0;

  display: flex;

  align-items: center;

  justify-content: center;

  margin-top: 108px;

  @media (--breakpoint-desktop) {
    position: relative;

    margin-top: 0;
  }

  font-family: var(--font-family-roobert-pro);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 100%;

  padding: 2em;

  margin-bottom: 5em;

  gap: var(--size-52);

  @media (--breakpoint-desktop) {
    flex-direction: row;

    gap: 0;
  }
}

.description {
  position: relative;

  display: flex;

  flex-direction: column;

  @media (--breakpoint-desktop) {
    width: calc(var(--5-cols) + 2 * var(--gutter));

    padding: var(--size-20);
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.form {
  margin-top: var(--size-20);

  @media (--breakpoint-desktop) {
    width: calc(var(--4-cols) + calc(2 * var(--gutter)));
    padding: var(--size-20);
    margin: 0;

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.formError {
  padding: var(--size-12);

  background: var(--rebranding-md-color-red-lightest);

  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  color: var(--rebranding-md-color-red);

  text-align: center;
}
